<template>
  <head>
    <title>Unauthorized access - My CSC</title>
  </head>

  <h2 class="text-8xl text-center">
    {{ error?.statusCode }}
  </h2>

  <p class="text-center">Unauthorized access</p>

  <c-button @click="handleError">Back to dashboard</c-button>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app';

defineProps<{
  error?: NuxtError;
}>();

const handleError = () => clearError({ redirect: '/dashboard' });
</script>
